"use client";

import React from "react";

import {iIcon} from "./Types";

const Icon = ({className, size = "20px", color = "inherit", name}: iIcon) => {
	return (
		<i
			className={`${className} ${name}`}
			style={{fontSize: size, color: color, fontWeight: 500}}
		></i>
	);
};

export default Icon;
