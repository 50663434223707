import Joi from "joi";

/**
 * Strings allowing only alphabets (A-Z and a-z) and spaces.
 */

const alphabetPattern = Joi.string()
	.regex(/^[A-Za-z ]+$/)
	.message("Only alphabets (A-Z and a-z) and spaces are allowed.");

/**
 * Strings allowing only a single space between words.
 */

const singleSpacePattern = Joi.string()
	.regex(/^(\w+\s?)+$/)
	.message("Only a single space between words is allowed.");

// Validation Schema for firstName

const firstName = Joi.string()
	.min(2)

	.max(250)

	.concat(alphabetPattern)

	.concat(singleSpacePattern)
	// remove leading and trailing white spaces from the string

	.trim()
	/**
	 * Custom validation function to ensure that the first letter of each word in the input string is capitalized.
	 *
	 * @param {string} value - The input string to be validated.
	 * @param {object} helpers - A helper object for validation.
	 *
	 * @returns {string|Error} The validated value if it passes the custom capitalization validation, or an error if validation fails.
	 */

	.custom((value, helpers) => {
		const words = value.split(" ");

		const isValidCapitalization = words.every((word: string) => {
			return word.charAt(0) === word.charAt(0).toUpperCase();
		});

		if (!isValidCapitalization) {
			return helpers.error("capitalization");
		}

		return value;
	})
	.required()
	.messages({
		"string.min": "Input length should be at least 2 characters.",
		"string.max": "Input length should not exceed 250 characters.",
		"string.empty": "Input should not contain empty spaces.",
		capitalization: "Each word should start with a capital letter.",
		"any.required": "First Name is required.",
	});

// Validation Schema for email

const email = Joi.string()
	.email({tlds: {allow: ["com", "net"]}}) //  It also allows only the "com" and "net" top-level domains (TLDs) for the email address
	.required()
	.messages({
		"string.email": "Invalid email format.",
		"any.required": "Email is required.",
	});

export const UserRegFormSchema = Joi.object({
	firstName: firstName,
	email: email,
});
