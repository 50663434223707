import React from "react";

import {iTypography} from "./Types";
import style from "./Styles";
import { useThemeAwareObject } from "../../styles/theme/ThemingHelper";
import { css } from "@emotion/css";

const Typo: React.FC<iTypography> = ({variant, children}) => {
	const styles = useThemeAwareObject(style);

	let tag: keyof JSX.IntrinsicElements = "div";

	if (variant === "h1") {
		tag = "h1";
	} else if (variant === "h2") {
		tag = "h2";
	} else if (variant === "subtitle") {
		tag = "span";
	} else if (variant === "body") {
		tag = "p";
	} else if (variant === "label") {
		tag = "span";
	}
	const Tag = tag; // Corrected variable name for using it as a Component

	return (
		<Tag 
		className={`${css`${styles.root}`+ " " +  variant}`}>
			{children}
		</Tag>
	);
};

export default Typo;
