import React from "react";

import Input from "../Input/Input";
import Button from "../Button";
import Icon from "../Icon";

import {UserRegFormSchema} from "./UserRegFormSchema";
import style from "./Styles";
import {useThemeAwareObject} from "../../styles/theme/ThemingHelper";
import {iUserFormData} from "./Types";
import {ValidationResultS, isValidate} from "../../utils/Validation";
import {iFormData} from "../../customTypes/FormTypes";
import generateErrorList from "../../utils/Error";
import {css} from "@emotion/css";
import langData from "../../db";
import Toggle from "../Toggle";
import {useTheme} from "../../styles/theme/ThemeContext";
import Typo from "../Typo/Typo";
import {emailSubscriptionService} from "../../services/api/evappServer";
import {NullableGenericServiceResult} from "../../customTypes/ApiServiceTypes";
import { EmailSubscriptionStatus } from "../../config/constants/EmailSubscriptionStatus";
import { ToggleDataLoadingStatus } from "../../config/constants/ToggleDataLoadingStatus";
import { ToastVariants } from "../../config/constants/ToastVariants";
import { UserScreenViewConst } from "../../config/constants/UserScreenView";

const onSubmitUserFormData = async (email: string) => {
	let emailSubscribeResponse: NullableGenericServiceResult<null> = null;

	try {
		emailSubscribeResponse = await emailSubscriptionService.emailSubscribe(
			email,
		);
		if (
			emailSubscribeResponse &&
			emailSubscribeResponse.hasOwnProperty("status")
		) {
			return emailSubscribeResponse;
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
};

const UserRegForm = ({
	setIsToastOpen,
	onSubmitSuccess,
}: {
	setIsToastOpen: any;
	onSubmitSuccess: any;
}) => {
	const styles = useThemeAwareObject(style);
	const {theme} = useTheme();

	const [userFormData, setUserFormData] = React.useState<iUserFormData>({
		firstName: "",
		email: "",
	});

	const [error, setError] = React.useState<ValidationResultS>({
		isValid: false,
		errors: {},
	});

	const [isLoading, setLoading] = React.useState<boolean>(
		!langData.signupScreen.footer.isDefaultToggleOfReceivingEmail,
	);

	const onClickToggle = (e: any) => {
		const dataStateValue = e.currentTarget.getAttribute("data-state");

		if (dataStateValue === ToggleDataLoadingStatus.ON) {
			setLoading(true);
		} else if (dataStateValue === ToggleDataLoadingStatus.OFF) {
			setLoading(false);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = e.target;

		setUserFormData({
			...userFormData,
			[name]: value,
		});
	};

	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();

				setLoading(true);

				const formData = new FormData(event.currentTarget);

				const data: iFormData = {};

				formData.forEach((value, key) => {
					data[key] = value.toString();
				});
				const validation = isValidate(data, UserRegFormSchema); // Validate the form data

				if (validation.isValid) {
					setIsToastOpen({
						isOpen: false,
						variant: ToastVariants.ERROR,
						message: "",
					});
					// Form is valid, proceed with submission
					onSubmitUserFormData(userFormData.email).then((res: any) => {
						if (res) {
							setLoading(false);
							if (res.status === EmailSubscriptionStatus.SUBSCRIBED) {
								onSubmitSuccess(UserScreenViewConst.SCREEN_FOR_SUBSCRIBED_SUCCESSFULLY);
							} else {
								setIsToastOpen({
									isOpen: true,
									variant: ToastVariants.ERROR,
									message: res.title,
									desc: res.detail,
								});
							}
						} else {
							setLoading(false);
							setIsToastOpen({
								isOpen: true,
								variant: ToastVariants.ERROR,
								message: langData.generic_fallback.unknown_error_title,
								desc: langData.generic_fallback.unknown_error_desc
							});
						}
					});

					setError({isValid: false, errors: {}});
				} else {
					setLoading(false);

					setError(validation);
					// Form is invalid, update the errors state
					// setValidationErrors(validation.errors);
				}
			}}
			style={{width: "100%"}}
		>
			<fieldset
				name="firstName"
				className={`${css`
					${styles.formField}
				`}`}
			>
				<div
					className={`${css`
						${styles.inputContainer}
					`}`}
				>
					<div
						className={`${css`
							${styles.input}
						`}`}
					>
						<Input
							placeholder={langData.signupScreen.input.firstName.label}
							name="firstName"
							icon={langData.signupScreen.input.firstName.icon}
							required={true}
							type="text"
							value={userFormData.firstName}
							handleInputChange={handleInputChange}
						/>
					</div>
					{generateErrorList(error.isValid, error.errors, "firstName")}
				</div>
			</fieldset>
			<fieldset
				name="email"
				className={`${css`
					${styles.formField}
				`}`}
			>
				<div
					className={`${css`
						${styles.inputContainer}
					`}`}
				>
					<div
						className={`${css`
							${styles.input}
						`}`}
					>
						<Input
							placeholder={langData.signupScreen.input.email.label}
							name="email"
							icon={langData.signupScreen.input.email.icon}
							required={true}
							type="email"
							value={userFormData.email}
							handleInputChange={handleInputChange}
						/>
					</div>
					{generateErrorList(error.isValid, error.errors, "email")}
				</div>
			</fieldset>

			<Button
				isDisabled={isLoading}
				btnLabel={langData.signupScreen.submitBtn.label}
				className="primary"
				width="100%"
				endIcon={<Icon size="20px" name={langData.signupScreen.submitBtn.icon} />}
			/>

			<div
				className={`${css`
					${styles.toggleAndTextContainer}
				`}`}
			>
				<Toggle
					defaultChecked={
						langData.signupScreen.footer.isDefaultToggleOfReceivingEmail
					}
					onClickToggle={onClickToggle}
				/>
				<Typo variant="subtitle">
					{langData.signupScreen.footer.toggleLabel}
				</Typo>
				<Icon
					size="20px"
					name="ri-information-line"
					color={theme.colors.accents.border}
				/>
			</div>
		</form>
	);
};

export default UserRegForm;
