"use client";

import React from "react";

import Typo from "../Typo/Typo";
import Divider from "../Divider";

import UserRegForm from "./UserRegForm";
import CustomToast from "../Toast/Toast";
import {iToastState} from "./Types";
import FallBack from "../FallBack";
import langData from "../../db";
import Icon from "../Icon";
import {useTheme} from "../../styles/theme/ThemeContext";
import {css} from "@emotion/css";
import {useThemeAwareObject} from "../../styles/theme/ThemingHelper";
import style from "./Styles";
import {
	UserScreenViewConst,
	defaultUserScreenView,
} from "../../config/constants/UserScreenView";
import {ToastVariants} from "../../config/constants/ToastVariants";

const renderSocialMediaIcon = (socialMediaItem: any, styles: any) => {
	if (socialMediaItem.isIconShow) {
		return (
			<>
				{socialMediaItem.iconList.map(
					({imgUrl, link}: {imgUrl: string; link: string}) => {
						return (
							<>
								<a
									href={link}
									target="_blank"
									rel="noopener noreferrer"
									key={link}
								>
									<img
										className={`${
											css`
												${styles.socialMediaIcon}
											` +
											" " +
											"socilaMediaIcontilt"
										}`}
										src={imgUrl}
										alt="socialMediaIcon"
									/>
								</a>
							</>
						);
					},
				)}
			</>
		);
	}
};

const renderSignupScreen = (
	screen: string,
	styles: any,
	theme: any,
	setIsToastOpen: any,
	onSubmitSuccess: any,
) => {
	if (screen !== UserScreenViewConst.SCREEN_FOR_SUBSCRIBED_SUCCESSFULLY) {
		return (
			<>
				<div
					className={`${css`
						${styles.imgContainer}
					`}`}
				>
					<img
						className={`${css`
							${styles.img}
						`}`}
						src={langData.signupScreen.heroSection.coverImgUrl}
						alt="cover"
					/>
				</div>
				<div
					className={`${css`
						${styles.heroTextWrapper}
					`}`}
				>
					<Typo variant="h2">{langData.signupScreen.heroSection.heading}</Typo>
					<Typo variant="body">{langData.signupScreen.heroSection.desc}</Typo>
				</div>

				<UserRegForm
					setIsToastOpen={setIsToastOpen}
					onSubmitSuccess={onSubmitSuccess}
				/>

				<Divider width="50%" />

				<div
					className={`${css`
						${styles.socialMediaIconWrapper}
					`}`}
				>
					{renderSocialMediaIcon(
						langData.signupScreen.footer.socailMediaIcon,
						styles,
					)}
				</div>
			</>
		);
	} else {
		return (
			<>
				<FallBack
					subHeader={langData.subscribtionSucessScreen.desc}
					header={langData.subscribtionSucessScreen.heading}
					svgIcon={
						<Icon
							size="55px"
							color={theme.colors.primary}
							name={langData.subscribtionSucessScreen.coverIcon}
						/>
					}
				/>
			</>
		);
	}
};

const Home = () => {
	const styles = useThemeAwareObject(style);
	const {theme} = useTheme();

	const [isToastOpen, setIsToastOpen] = React.useState<iToastState>({
		isOpen: false,
		message: "",
		variant: ToastVariants.ERROR,
		desc: "",
	});

	const [screen, setScreen] = React.useState<any>(defaultUserScreenView);

	const onSubmitSuccess = (status: any) => {
		if (status === UserScreenViewConst.SCREEN_FOR_SUBSCRIBED_SUCCESSFULLY) {
			setScreen(UserScreenViewConst.SCREEN_FOR_SUBSCRIBED_SUCCESSFULLY);
		}
	};

	return (
		<>
			<div
				className={`${css`
					${styles.signup}
				`}`}
			>
				<div
					className={`${css`
						${styles.signupContainer}
					`}`}
				>
					{renderSignupScreen(
						screen,
						styles,
						theme,
						setIsToastOpen,
						onSubmitSuccess,
					)}
					<CustomToast
						isToastOpen={isToastOpen}
						setIsToastOpen={setIsToastOpen}
					/>
				</div>
			</div>
		</>
	);
};

export default Home;
