"use client";

import React from "react";

import Icon from "../Icon";

import { iInput } from "./Types";
import style from "./Styles";
import { useThemeAwareObject } from "../../styles/theme/ThemingHelper";
import { css } from "@emotion/css";
import { useTheme } from "../../styles/theme/ThemeContext";

const Input = ({
  name,
  type,
  required = false,
  iconSize,
  icon = "15px",
  handleInputChange,
  placeholder,
  defaultValue,
  value,
}: iInput) => {
  const styles = useThemeAwareObject(style);
	const {theme} = useTheme();

  return (
    <>
      <div
        className={`${css`
          ${styles.inputContainer}
        `}`}
      >
        <input
          className={`${css`
            ${styles.input}
          `}`}
          name={name}
          type={type}
          required={required}
          defaultValue={defaultValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          value={value}
        />
        {icon && (
          <div
            className={`${css`
              ${styles.iconWrapper}
            `}`}
          >
            <Icon size={iconSize} name={icon} color={theme.colors.accents.border}/>
          </div>
        )}
      </div>
    </>
  );
};
export default Input;
