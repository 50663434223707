import {createStyles} from "../../customTypes/StyleTypes";
import { iTheme } from "../../customTypes/ThemeTypes";

const style = (theme: iTheme) => {
	return createStyles({
		signup: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			height: "100vh",
			padding: "20px",
			backgroundColor: theme.colors.neutrals.background.screen,
		},
		signupContainer: {
			maxWidth: 600,
			width: "100%",
			display: "flex",
			gap: 20,
			flexDirection: "column",
		},
		imgContainer: {
			width: "100%",
			height: "150px",
			alignSelf: "center",
		},
		img: {
			width: "100%",
			height: "100%",
			objectFit: "cover",
		},
		heroTextWrapper: {
			display: "flex",
			flexDirection: "column",
			gap: 5,
		},
		socialMediaIconWrapper: {
			display: "flex",
			flexDirection: "row",
			gap: 20,
			justifyContent: "center",
			alignItems: "center",
		},
		inputContainer: {
			display: "flex",
			alignItems: "baseline",
			flexDirection: "column",
			gap: "5px",
		},
		input: {
			width: "100%",
		},
		toggleAndTextContainer: {
			display: "flex",
			flexDirection: "row",
			gap: 10,
			alignItems: "center",
			marginTop: "30px",
		},
		formField: {
			display: "grid",
			outline: "none",
			border: "none",
			marginBottom: "15px",
		},
		socialMediaIcon:{
			width: "25px",
			height: "25px",
			objectFit: "cover",
			cursor: "pointer",
		}
	});
};
export default style;
