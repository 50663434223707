import React from "react";
import Label from "../components/Label";

const generateErrorList = (
	isValid: boolean,
	error: Record<string, string[]>,
	field: string,
) => {
	if (!isValid && field in error && error[field].length > 0) {
		return (
			<>
				{error[field].map((item: string) => {
					return (
						<>
							<Label value={item} textAlign="left" className="error" />
						</>
					);
				})}
			</>
		);
	}
};
export default generateErrorList;
