import { createStyles } from "../../customTypes/StyleTypes";
import { iTheme } from "../../customTypes/ThemeTypes";

const style = (themes: iTheme) => {
  return createStyles({
    root: {
      backgroundColor: "transparent",
      color: `${themes.colors.accents.border}`,
      height: "25px",
      width: "25px",
      borderRadius: "4px",
      display: "flex",
      fontSize: "15px",
      lineHeight: "1",
      alignItems: "center",
      justifyContent: "center",
      border: `1px solid ${themes.colors.accents.border} !important`,
      "&:hover": {
        filter: `brightness(0.7)`,
      },
      "&[data-state='on']": {
        backgroundColor: themes.colors.primary,
        color: themes.colors.neutrals.font.label,
      },
      "&:focus": {
        // boxShadow: "0 0 0 1px black",
      },
    },
  });
};
export default style;
