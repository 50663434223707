import React from "react";

import * as Separator from "@radix-ui/react-separator";

import style from "./Styles";
import {useThemeAwareObject} from "../../styles/theme/ThemingHelper";
import {css} from "@emotion/css";

const Divider = ({width}: {width?: string}) => {
	const styles = useThemeAwareObject(style);

	return (
		<div
			className={`${css`
				${styles.separatorRootContainer}
			`}`}
			style={{width: width}}
		>
			<Separator.Root
				className={`${css`
					${styles.separatorRoot}
				`}`}
				orientation="horizontal"
			/>
		</div>
	);
};

export default Divider;
