
import { iColorTheme, iTheme } from "../../customTypes/ThemeTypes";
import Colors from "../Colors";

const colors: iColorTheme = {
	primary: Colors.MAJORELLE_BLUE,

	secondary: "#d3a1ff",

	accents: {
		border: "#D9D9D9",
	},

	neutrals: {

		background: {
			screen: "white",
			input: "transparent"
		},

		font: {
			h1: Colors.DARK_GUNMETAL,

			h2: Colors.DARK_GUNMETAL,

			subtitle: Colors.DARK_GUNMETAL,

			body: Colors.DARK_GUNMETAL,

			label: Colors.WHITE,
		},
	},

	semantic: {
		error: {
			bg: "#FFECEC",
			color: "#BC3737" ,
		},

		warning: {
			bg: "#FFECDA",
			color: "#EE860A",
		},

		success: {
			bg: "#DEFFE1",
			color: "#28D138",
		},

		info: {
			bg: Colors.MAJORELLE_BLUE,
			color: Colors.MAJORELLE_BLUE,
		},
	},
};

const DefaultLightTheme: iTheme = {
	id: "default-light-theme",

	isDark: false,

	colors,
};

export default DefaultLightTheme;
