import { createStyles } from "../../customTypes/StyleTypes";
import { iTheme } from "../../customTypes/ThemeTypes";
import Typography from "../../styles/Typography";

const style = (themes: iTheme) => {
	return createStyles({
		root: {
			borderRadius: "6px",
			display: "flex",
			flexDirection: "row",
			gap: "10px",
			height: "40px",
			maxHeight: "40px",
			justifyContent: "center",
			alignItems: "center",
			fontSize: "16px",
			border: `none`,
			cursor: "pointer",
			transition: "background-color 0.2s ease",
			backgroundColor: themes.colors.primary,
			color: themes.colors.neutrals.font.label,
			fontFamily: Typography.fonts.MONTSERRAT,
			"&:focus": {
				outline: "none",
			},

			"&.primary": {
				backgroundColor: themes.colors.primary,
				color: themes.colors.neutrals.font.label,

				"&:hover": {
					filter: `brightness(1.1)`,
				},
				"&:focus-within": {
					boxShadow: `0 0 0 1px black`,
				},
				"&:disabled": {
					opacity: "0.3",
					filter: "grayscale(100%)",
					cursor: "not-allowed",
				},
			},
			"&.fullWidthOnSmallScreen": {
				"@media (max-width: 750px)": {width: "100% !important"},
			},
		},
	});
};

export default style;
