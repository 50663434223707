import React from "react";

import Typo from "../Typo/Typo";

import { iButton } from "./Types";
import style from "./Styles";
import { useThemeAwareObject } from "../../styles/theme/ThemingHelper";
import { css } from "@emotion/css";

const Button = ({
  className,
  width,
  endIcon,
  btnLabel,
  onclick,
  isDisabled = false,
}: iButton) => {
  const styles = useThemeAwareObject(style);

  return (
    <button
      className={`${
        css`
          ${styles.root}
        ` +
        " " +
        className
      }`}
      type="submit"
      disabled={isDisabled}
      onClick={onclick}
      aria-label="button"
      style={{ width: width }}
    >
      {endIcon && endIcon}

      <Typo variant="label">{btnLabel}</Typo>
    </button>
  );
};

export default Button;
