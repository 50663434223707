import * as React from "react";

import * as Toast from "@radix-ui/react-toast";

import Icon from "../Icon";

import style from "./Styles";
import {iToast} from "./Types";
import {useThemeAwareObject} from "../../styles/theme/ThemingHelper";
import {css} from "@emotion/css";
import {useTheme} from "../../styles/theme/ThemeContext";
import {iTheme} from "../../customTypes/ThemeTypes";
import { ToastVariants } from "../../config/constants/ToastVariants";
import langData from "../../db";

const generateIconName = (variant: string) => {
	if (variant === ToastVariants.ERROR) {
		return langData.generic_fallback.error_icon;
	} else if (variant === ToastVariants.INFO) {
		return "ri-checkbox-circle-fill";
	} else {
		return "ri-checkbox-circle-fill";
	}
};

const generateIconColor = (variant: string, theme: iTheme) => {
	if (variant === ToastVariants.ERROR) {
		return theme.colors.semantic.error.color;
	} else if (variant === ToastVariants.INFO) {
		return theme.colors.semantic.info.color;
	} else {
		return theme.colors.semantic.success.color;
	}
};

const CustomToast = ({isToastOpen, setIsToastOpen}: iToast) => {
	const styles = useThemeAwareObject(style);
	const {theme} = useTheme();

	const handleToastToggle = (value: boolean) => {
		setIsToastOpen({
			...isToastOpen,
			isOpen: value,
		});
	};

	return (
		<Toast.Provider swipeDirection="right">
			<Toast.Root
				className={`${
					css`
						${styles.ToastRoot}
					` +
					" " +
					isToastOpen.variant
				}`}
				open={isToastOpen.isOpen}
				onOpenChange={handleToastToggle}
				duration={5000}
			>
				<Toast.Title
					className={`${
						css`
							${styles.ToastTitle}
						` +
						" " +
						isToastOpen.variant
					}`}
				>
					{isToastOpen.message}
				</Toast.Title>

				<Toast.Description
					className={`${css`
						${styles.ToastDesc}
					`}`}
				>
					{isToastOpen.desc}
				</Toast.Description>

				<div
					className={`${
						css`
							${styles.ToastIcon}
						` +
						" " +
						isToastOpen.variant
					}`}
				>
					<Icon
						size="30px"
						name={generateIconName(isToastOpen.variant)}
						color={generateIconColor(isToastOpen.variant, theme)}
					/>
				</div>
			</Toast.Root>
			<Toast.Viewport
				className={`${css`
					${styles.ToastViewport}
				`}`}
			/>
		</Toast.Provider>
	);
};

export default CustomToast;
