import React from 'react';

import './App.css';
import Home from './components/Home/Home';
import "remixicon/fonts/remixicon.css";

function App() {
  return (
    <div className="App">
       <Home/>
    </div>
  );
}

export default App;
