import {AxiosInstance} from "axios";



import {apiEndpoints} from "./axiosConfig/AxiosServiceConstants";
import buildResultOnFullFilled, { buildResultOnRejected } from "../../../utils/NetworkUtil";
import { NullableGenericServiceResult } from "../../../customTypes/ApiServiceTypes";

function EmailSubscriptionService(apiServer: AxiosInstance) {
	/**
	 * API Service method for subscribing to email list.
	 *
	 * @param email - The email to be subscribed.
	 * @returns Promise<APIResponse | null>
	 */
	const emailSubscribe = async (
		email: string,
	): Promise<NullableGenericServiceResult<null>> => {

		let result: NullableGenericServiceResult<null> = null;

		await apiServer
			.post(apiEndpoints.subscribe.emailSubscription, {
				email,
			})
			.then(
				// onFullFilled
				(value) => {
					result = buildResultOnFullFilled(value);
				},

				// onRejected
				(reason) => {
					result = buildResultOnRejected(reason);
				},
			)
			.catch((error) => {
				throw error;
			});

		return result;
	};

	return {
		emailSubscribe,
	};
}

export default EmailSubscriptionService;