import React from "react";
import * as Toggle from "@radix-ui/react-toggle";
import {useThemeAwareObject} from "../../styles/theme/ThemingHelper";
import style from "./Styles";
import Icon from "../Icon";
import {css} from "@emotion/css";

const CustomToggle = ({
	defaultChecked,
	onClickToggle,
}: {
	defaultChecked: boolean;
	onClickToggle: any;
}) => {
	const styles = useThemeAwareObject(style);

	return (
		<>
			<Toggle.Root
				defaultPressed={defaultChecked}
				onClick={onClickToggle}
				className={`${css`
					${styles.root}
				`}`}
				aria-label="Toggle italic"
			>
				<Icon size="20px" name="ri-check-line" />
			</Toggle.Root>
		</>
	);
};

export default CustomToggle;
