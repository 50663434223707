/**
 * Constants representing variants for toast messages.
 * These constants are frozen to prevent modification.
 */

export const ToastVariants = Object.freeze({
    ERROR: "error",
    SUCCESS: "success",
    WARNING: "warning",
    INFO: "info"
});
