import { createStyles } from "../../customTypes/StyleTypes";

const style = () => {
  return createStyles({
    fallbackContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      gap: "15px",
      padding: "20px",
      h2: {
        textAlign: "center",
      },
    },
  });
};

export default style;
