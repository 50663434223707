import { createStyles } from "../../customTypes/StyleTypes";
import Typography from "../../styles/Typography";

const style = () => {
	return createStyles({
		root: {
			fontSize: "16px",
			fontWeight: 400,
			color: "gray",
			fontFamily: Typography.fonts.MONTSERRAT,
			"&.error": {
				fontSize: "13px",
				fontWeight: 400,
				color: "red",
			},
			"&.hint": {
				fontSize: "12px",
				fontWeight: 100,
				color: "#535252",
			},
		},
	});
};

export default style;
