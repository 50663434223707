import { createStyles } from "../../customTypes/StyleTypes";
import { iTheme } from "../../customTypes/ThemeTypes";
import Typography from "../../styles/Typography";

const style = (themes: iTheme) => {
	return createStyles({
		input: {
			fontFamily: Typography.fonts.MONTSERRAT,
			fontWeight: 400,
			width: "100%",
			display: "inline-flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "4px",
			fontSize: "14px",
			color: "black",
			backgroundColor: themes.colors.neutrals.background.input,
			padding: "0 16px 0px 10px",
			height: "40px",
			lineHeight: "1",
			border: `1px solid ${themes.colors.accents.border}`,
			"&:hover": {
				boxShadow: `0 0 0 2px ${themes.colors.secondary}`,
			},
			"&:focus": {
				boxShadow: `0 0 0 1px ${themes.colors.primary}`,
				outline: "none",
			},
			"&::selection": {
				backgroundColor: themes.colors.secondary,
				color: themes.colors.neutrals.font.h1,
			},
		},
		inputContainer: {position: "relative"},
		iconWrapper: {
			position: "absolute",
			top: "50%",
			right: "16px" /* Adjust the right value for icon positioning */,
			transform: "translateY(-50%)",
			paddingLeft: "10px",
			backgroundColor: "transparent",
		},
	});
};

export default style;
