import { createStyles } from "../../customTypes/StyleTypes";
import { iTheme } from "../../customTypes/ThemeTypes";
import Typography from "../../styles/Typography";

const style = (themes: iTheme) => {
	return createStyles({
		root: {
			"&.h1": {
				fontSize: "32px",
				fontFamily: Typography.fonts.MONTSERRAT
			},
			"&.h2": {
				fontSize: "25px",
				fontWeight: 700,
				color: themes.colors.neutrals.font.h2,
				fontFamily: Typography.fonts.MONTSERRAT
			},
			"&.subtitle": {
				fontSize: "14px",
				fontWeight: 500,
				color: themes.colors.neutrals.font.subtitle,
				fontFamily: Typography.fonts.MONTSERRAT
			},
			"&.body": {
				fontSize: "14px",
				fontWeight: 400,
				color: themes.colors.neutrals.font.body,
				lineHeight: 1.8,
				fontFamily: Typography.fonts.MONTSERRAT
			},
			"&.label": {
				fontSize: "16px",
				fontWeight: 600,
				color: themes.colors.neutrals.font.label,
				fontFamily: Typography.fonts.MONTSERRAT
			},
		},
	});
};

export default style;
