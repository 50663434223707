import {createStyles} from "../../customTypes/StyleTypes";
import {iTheme} from "../../customTypes/ThemeTypes";

const style = (themes: iTheme) => {
	return createStyles({
		separatorRootContainer: {
			margin: "auto",
		},
		separatorRoot: {
			backgroundColor: themes.colors.accents.border,
			"&[data-orientation=horizontal]": {
				height: "0.5px",
				width: "100%",
			},
		},
	});
};

export default style;
