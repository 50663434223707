import {keyframes} from "@emotion/react";
import {createStyles} from "../../customTypes/StyleTypes";
import {iTheme} from "../../customTypes/ThemeTypes";
import Typography from "../../styles/Typography";

const hide = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(calc(100% + 25px));
  }
  to {
    transform: translateX(0);
  }
`;

const swipeOut = keyframes`
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + 25px));
  }
`;

const style = (themes: iTheme) => {
	return createStyles({
		ToastViewport: {
			position: "fixed",
			top: 0,
			left: "50%",
			transform: "translateX(-50%)",
			display: "flex",
			flexDirection: "column",
			padding: "25px",
			gap: "10px",
			width: "390px",
			maxWidth: "100vw",
			margin: 0,
			listStyle: "none",
			zIndex: 2147483647,
			outline: "none",
		},
		ToastRoot: {
			backgroundColor: themes.colors.semantic.error.bg,
			borderRadius: "6px",
			boxShadow:
				"hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
			padding: "10px",
			display: "grid",
			gridTemplateAreas: `
		'icon title title action'
		'icon description description action'
  `,
			gridTemplateColumns: "auto",
			columnGap: "25px",
			justifyContent: "left",
			alignItems: "center",
			"&[data-state=open]": {
				animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
			},
			"&[data-state=closed]": {
				animation: `${hide} 100ms ease-in`,
			},
			"&[data-swipe=move]": {
				transform: "translateX(var(--radix-toast-swipe-move-x))",
			},
			"&[data-swipe=cancel]": {
				transform: "translateX(0)",
				transition: "transform 200ms ease-out",
			},
			"&[data-swipe=end]": {
				animation: `${swipeOut} 100ms ease-out`,
			},
			"&.success": {
				backgroundColor: "white",
				borderTop: `4px solid ${themes.colors.semantic.success.color}`,
			},

			"&.error": {
				backgroundColor: "white",
				borderTop: `4px solid ${themes.colors.semantic.error.color}`,
			},
			"&.info": {
				backgroundColor: themes.colors.semantic.info.bg,
			},
		},
		ToastTitle: {
			gridArea: "title",
			fontWeight: 700,
			color: themes.colors.semantic.error.color,
			fontSize: "17px",
			fontFamily: Typography.fonts.MONTSERRAT,
			textTransform: "capitalize",

			"&.success": {
				color: themes.colors.semantic.success.color,
			},
			"&.error": {
				color: themes.colors.semantic.error.color,
			},
			"&.info": {
				color: themes.colors.semantic.info.color,
			},
		},

		ToastDesc: {
			gridArea: "description",
			color: themes.colors.neutrals.font.h1,
			fontWeight: 400,
			fontSize: "14px",
			lineHeight: "1.6",
			fontFamily: Typography.fonts.MONTSERRAT,
		},
		ToastAction: {
			gridArea: "action",
		},
		ToastIcon: {
			gridArea: "icon",
			backgroundColor: themes.colors.semantic.error.bg,
			padding: "5px",
			borderRadius: "10px",

			"&.success": {
				backgroundColor: themes.colors.semantic.success.bg,
			},
			"&.error": {
				backgroundColor: themes.colors.semantic.error.bg,
			},
			"&.info": {
				backgroundColor: themes.colors.semantic.info.bg,
			},
		},
		ToastClose: {
			gridArea: "close",
			alignSelf: "flex-start",
		},
	});
};
export default style;
