/**
 * @description
 * Constants for different views/screens.
 * These constants are frozen to prevent modification.
*/

export const UserScreenViewConst = Object.freeze({
	SIGNUP_SCREEN: "SIGNUP_SCREEN",
	SCREEN_FOR_SUBSCRIBED_SUCCESSFULLY: "SCREEN_FOR_SUBSCRIBED_SUCCESSFULLY"
});

export const defaultUserScreenView = UserScreenViewConst.SIGNUP_SCREEN;
