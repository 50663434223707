import React from "react";

import * as Label from "@radix-ui/react-label";

import { iLabel } from "./Types";
import style from "./Styles";
import { useThemeAwareObject } from "../../styles/theme/ThemingHelper";
import { css } from "@emotion/css";

const InputLabel = ({ value, htmlFor, textAlign, className }: iLabel) => {
  const styles = useThemeAwareObject(style);

  return (
    <Label.Root
      className={`${
        css`
          ${styles.root}
        ` +
        " " +
        className
      }`}
      htmlFor={htmlFor}
      style={{ textAlign: textAlign }}
    >
      {value}
    </Label.Root>
  );
};

export default InputLabel;
