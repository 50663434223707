import React from "react";

import Typo from "../Typo/Typo";
import Button from "../Button";
import Icon from "../Icon";

import style from "./Styles";
import { iFallback } from "./Types";
import { useThemeAwareObject } from "../../styles/theme/ThemingHelper";
import { css } from "@emotion/css";
import langData from "../../db";

const FallBack = ({ header, subHeader, svgIcon }: iFallback) => {
  const styles = useThemeAwareObject(style);

  const onRegisterBtnClick = () => {
    window.location.reload();
  };

  return (
    <div
      className={`${css`
        ${styles.fallbackContainer}
      `}`}
    >
      {svgIcon}
      <Typo variant="h2">{header}</Typo>
      <Typo variant="subtitle">{subHeader}</Typo>
      <Button
        onclick={onRegisterBtnClick}
        btnLabel={langData.subscribtionSucessScreen.btnLabel}
        className="primary fullWidthOnSmallScreen"
        width="239px"
        endIcon={<Icon size="20px" name={langData.subscribtionSucessScreen.btnIcon}/>}
      />
    </div>
  );
};

export default FallBack;
